import resume from '../assets/pdf/resume.pdf'
import profpic from '../assets/jpeg/pic-andrico.jpeg'

export const headerData = {
    name: 'Andrico Mauludi Junianto',
    title: "DevOps Engineer Specialist",
    desciption:"Life may be a meandering path but don't worry about it, rather enjoy it. It is about the journey, not the destination so explore all that comes your way, learn and grow from it, for this journey only happens once.",
    image: profpic,
    resumePdf: resume
}
